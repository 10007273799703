// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* General page body */

html,
body {
  width: 100%;
  margin: 0;
  font-family: "Fakt", sans-serif, Helvetica, Arial;
}

*,
:after,
:before {
  box-sizing: border-box;
}

a,
u {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.hidden {
  display: none;
}

#header {
  background: #fff;
  border-bottom: 1px solid #e6e9eb;
  height: 44px;
  left: 0;
  margin-bottom: 24px;
  padding: 14px 26px;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 2;
  box-sizing: border-box;
}

/* Buttons */

.button {
  background: #00112c;
  border: 0;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  font-weight: 500;
  margin: 0;
  padding: 15px;
  text-align: center;
  transition: background 0.3s ease-out, box-shadow 0.3s ease-out;
  width: 100%;
}

.button:hover {
  background: #1c3045;
  box-shadow: 0 3px 4px rgba(0, 15, 45, 0.2);
}

.button:active {
  background: #3a4a5c;
}

.button:disabled {
  background: #e6e9eb;
  box-shadow: none;
  cursor: not-allowed;
  -webkit-user-select: all;
  user-select: all;
}

`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,sBAAsB;;AAEtB;;EAEE,WAAW;EACX,SAAS;EACT,iDAAiD;AACnD;;AAEA;;;EAGE,sBAAsB;AACxB;;AAEA;;EAEE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,gCAAgC;EAChC,YAAY;EACZ,OAAO;EACP,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,MAAM;EACN,WAAW;EACX,UAAU;EACV,sBAAsB;AACxB;;AAEA,YAAY;;AAEZ;EACE,mBAAmB;EACnB,SAAS;EACT,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,qBAAqB;EACrB,cAAc;EACd,gBAAgB;EAChB,SAAS;EACT,aAAa;EACb,kBAAkB;EAClB,8DAA8D;EAC9D,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,0CAA0C;AAC5C;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;EACnB,wBAAwB;EAGxB,gBAAgB;AAClB","sourcesContent":["/* General page body */\n\nhtml,\nbody {\n  width: 100%;\n  margin: 0;\n  font-family: \"Fakt\", sans-serif, Helvetica, Arial;\n}\n\n*,\n:after,\n:before {\n  box-sizing: border-box;\n}\n\na,\nu {\n  text-decoration: none;\n}\n\na:hover {\n  text-decoration: none;\n}\n\n.hidden {\n  display: none;\n}\n\n#header {\n  background: #fff;\n  border-bottom: 1px solid #e6e9eb;\n  height: 44px;\n  left: 0;\n  margin-bottom: 24px;\n  padding: 14px 26px;\n  position: fixed;\n  text-align: center;\n  top: 0;\n  width: 100%;\n  z-index: 2;\n  box-sizing: border-box;\n}\n\n/* Buttons */\n\n.button {\n  background: #00112c;\n  border: 0;\n  border-radius: 6px;\n  color: #fff;\n  cursor: pointer;\n  display: inline-block;\n  font-size: 1em;\n  font-weight: 500;\n  margin: 0;\n  padding: 15px;\n  text-align: center;\n  transition: background 0.3s ease-out, box-shadow 0.3s ease-out;\n  width: 100%;\n}\n\n.button:hover {\n  background: #1c3045;\n  box-shadow: 0 3px 4px rgba(0, 15, 45, 0.2);\n}\n\n.button:active {\n  background: #3a4a5c;\n}\n\n.button:disabled {\n  background: #e6e9eb;\n  box-shadow: none;\n  cursor: not-allowed;\n  -webkit-user-select: all;\n  -moz-user-select: all;\n  -ms-user-select: all;\n  user-select: all;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
